import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';

import {GET_EDITFONT,GET_FONTDETAILLIST }  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const FontId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			fontname:'',
			fontweight:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			c_font_id: FontId,
			Loading:false,
			maintabFontSize : '',
			menuFontSize : '',
			primaryFontSize : '',
			letterSpacing : '',
			lineHeight : '',
			font_type:'google_font',
		};
		//this.props.getFontDetailList(FontId);

		axios.get(apiUrl+"font/listdetail?id="+FontId).then(res => {
			
			
			if(res.data.fontlist.tf_status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			
			this.setState({fontname:res.data.fontlist.tf_name,fontweight:res.data.fontlist.tf_fontweight,maintabFontSize:res.data.fontlist.tf_maintabfontsize,menuFontSize:res.data.fontlist.tf_menufontsize, primaryFontSize:res.data.fontlist.tf_primaryfontsize,fonturl: res.data.fontlist.tf_import_url,fontfamilyname: res.data.fontlist.tf_font_family,font_type:res.data.fontlist.tf_font_type,letterSpacing:res.data.fontlist.tf_letterSpacing,lineHeight:res.data.fontlist.tf_lineHeight});
			
			
		}); 

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Font Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_font_id : formPayload.c_font_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status       : status,
					fontweight : formPayload.fontweight,
					maintabFontSize : formPayload.maintabFontSize,
					menuFontSize : formPayload.menuFontSize,
					primaryFontSize : formPayload.primaryFontSize,
					letterSpacing : formPayload.letterSpacing,
					lineHeight : formPayload.lineHeight,
					font_type:formPayload.font_type,
				};
				//console.log(postObject);
				//this.props.getEditFont(qs.stringify(postObject));
				let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				var filesdata = this.state.font_file;
				
				for (var i in filesdata) {
				 formData.append('font_file[]',filesdata[i])
				}

				axios.post(apiUrl+"font/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/fonts'));
								}
								.bind(this),
								3000
								);
								
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {fontname,fontfamilyname,selectedFile,fonturl,fontweight,maintabFontSize,menuFontSize,primaryFontSize,font_type,font_file,letterSpacing,lineHeight} = this.state;
		let errors = 0;

      	let formIsValid = true;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		
		if (font_type == 'google_font') {
			if (!fonturl) {
				errors++;
				$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfonturl').html('');
			}
		}else if(font_type == 'normal_font'){
			if(font_file.length==0){
				errors++;
				$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			}else{
				$('.errorfile').html('');	
			}

			if (!fontweight) {
				errors++;
				$('.errorfontweight').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfontweight').html('');
			}
		}

		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

   

	onFileChange = (e) =>{
		this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i])
				this.imageArray.push(this.fileObj[0][i])
	
			}
			this.setState({ font_file: this.imageArray })		
		}
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="fontlist" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Font Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
				</div>
			
              	<div className="form-group">
					<label>Exact Family Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>

				<div className="form-group">					
					<label>Font type<span class="required" style={{ color: "red" }} > * </span> :</label><br />
					Google Font <input type="radio" name='font_type' value="google_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'google_font') ? 'checked' : '' } /> <br />
					Normal Font<input type="radio" name='font_type' value="normal_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'normal_font') ? 'checked' : '' } /><br />
					
					<div className="error_font_type"></div>
	           </div>

			   <div className={this.state.font_type == 'google_font'?"show-font-type":"hide-font-type"}>
				
					<div className="form-group">
						<label>Font Import url:</label>
						<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
						<div className="errorfonturl"></div>
					</div>
				</div>

				<div className={this.state.font_type == 'normal_font'?"show-font-type":"hide-font-type"}>
					<div className="form-group">
						<label>Font<span class="required" style={{ color: "red" }} > * </span> :</label>
					<input type="file" onChange={this.onFileChange} multiple /> 
					<div className="errorfile"></div> 
					</div>
				</div>	
					<div className="form-group">
						<label>Font Weight :</label>
						<input type="text" name="fontweight" onChange={this.handleInputChange} className="form-control" value={this.state.fontweight} autoComplete="off" />
						<div className="errorfontweight"></div>
					</div>
					<div className="form-group">
						<label>Main Tab Font Size</label>
						<input type="text" name="maintabFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.maintabFontSize} autoComplete="off" />
						<div className="errormaintabFontSize"></div>
					</div>
					<div className="form-group">
						<label>Menu Font Size</label>
						<input type="text" name="menuFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.menuFontSize} autoComplete="off" />
						<div className="errormenuFontSize"></div>
					</div>
					<div className="form-group">
						<label>Primary Font Size</label>
						<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
						<div className="errorprimaryFontSize"></div>
					</div>

					<div className="form-group">
						<label>Primary Font Size</label>
						<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
						<div className="errorprimaryFontSize"></div>
					</div>

					<div className="form-group">
						<label>Letter Spacing</label>
						<input type="text" name="letterSpacing" onChange={this.handleInputChange} className="form-control" value={this.state.letterSpacing} autoComplete="off" />
						<div className="errorletterSpacing"></div>
					</div>

					<div className="form-group">
						<label>Line Height</label>
						<input type="text" name="lineHeight" onChange={this.handleInputChange} className="form-control" value={this.state.lineHeight} autoComplete="off" />
						<div className="errorlineHeight"></div>
					</div>
				
				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>				

			</div>	

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
   fontdetails: state.fontdetails,
   fontedit: state.fontedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFontDetailList: (FontId) =>{
       dispatch({ type: GET_FONTDETAILLIST,FontId });
    },
     getEditFont: (formPayload) =>{
       dispatch({ type: GET_EDITFONT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));*/
export default (withRouter(Edit));