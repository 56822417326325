import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { lang } from '../Helpers/lang';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      membershiplist:'',
      domainlist:[],
      activePage:1,
      sno_index:0,
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    }

    
componentDidMount(){
  this.listloadedData()
}
    listloadedData(postobject='') {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      if(postobject!=''){
        var postobject =postobject
      }else{
        var postobject = {
          activePage: 1,
          search_all: '',
          admin_id: admin_id,
          loggedas: loggedas
          }; 
      }
       

      axios.post(apiUrl+"membership/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  membershiplist: res.data.membershiplist, totalRecord: res.data.records_count}, function() {
            
              this.membershiplist();
            });
  
        }else{
          this.setState({  membershiplist:null, totalRecord: 0}, function() {
            
            this.membershiplist();
          });
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getmembershiplist(qs.stringify(postobject))
    this.listloadedData(postobject)

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }


  deletemembership(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };

  axios.post(apiUrl+"membership/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){       
          window.location.reload(); 
    }
  }); 
}

activatemembership(){
  let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };

        if(stat == "A"){
          axios.post(apiUrl+"membership/checkBeforeChange",qs.stringify(postObject)).then(res => {
            if(res.data.status === 'success'){
              this.change_status(postObject);
            }else{
              $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
              setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
            }
          });
        }else{
          this.change_status(postObject);
        }
}
change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"membership/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
     this.listloadedData()
     $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
    }
  }); 
}

confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activatemembership();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
membershiplist() {
    var membershiplist = this.state.membershiplist;
    var sno_index = this.state.sno_index;
    if (membershiplist != "undefined" && membershiplist != null) {
      // console.log(membershiplis);
    
      // if (membershiplist.status == "success") {

        if (Object.keys(membershiplist).length > 0) {
          const helperlistDetails = membershiplist.map(
            (membershiplist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; } 
              let sno = Index+1;
              

               if(membershiplist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
              // var istyle = { width: 200};
              return (
                <tr key={membershiplist.membership_type_id}>
      					<td>{sno}</td>
      					<td>{membershiplist.title}</td>
      					<td>{membershiplist.category_name}</td>
      					<td>{membershiplist.membfor}</td>
      					<td>${membershiplist.amount}</td>
      					<td>{(membershiplist.period ? membershiplist.period + "ly" : "")}</td>
                <td>{lang.Capitalize(status)}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/membership-view/'+membershiplist.membership_type_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/membership-edit/'+membershiplist.membership_type_id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, membershiplist.membership_type_id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, membershiplist.membership_type_id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      
                     {/* <Link onClick={this.deletemembership.bind(this,membershiplist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
  <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the membership?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="membership" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Memberships</h3>
                </div>
               
               
                <div className="add_button">  
                    <a className="" href='/membership-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Category</th>
			            <th>Membership</th>
                  <th>Plan for</th>
                  <th>Amount</th>
                  <th>Period</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.membershiplist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));