import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { mediaUrl,apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CKEditor from 'ckeditor4-react';


class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const memId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            memId: memId,
			m_title:'',
			description:'',
			amount:0,
			freeleads:0,
			period:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedMembForOption:{value: 'lender', label: 'Lender'},
			selectedPeriodOption: { value: 'Month', label: 'Month' },
			selectedPlanOption: "",
			selectedvalue:'active',
			selectedMembForvalue:'lender',
			selectedPeriodvalue:'',
			selectedPlanvalue:'',
			planLists:'',
			old_membership_img:'',
			selectedOptionChat:0,
			selectedOptionUnlimitedCall:0,
			selectedOptionAI:0,
			selectedOptionbankStm:0,
			//showAmen:0
		};
		var qs = require('qs');
		var memid = this.state.memId;

		axios.get(apiUrl+"membership/listdetail?id="+memid).then(res => {
			console.log(res, 'res000');
			this.setState({m_title:res.data.membershiplist.title,description:res.data.membershiplist.description,amount:res.data.membershiplist.amount,freeleads:res.data.membershiplist.utilizable_count,period:res.data.membershiplist.period,selectedPlanvalue:res.data.membershiplist.membership_category_id});
			if(res.data.membershiplist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}

			if(res.data.membershiplist.status === 'lender'){
				this.setState({selectedMembForOption:{value: 'lender', label: 'Lender'}});
			}else{
				this.setState({selectedMembForOption:{value: 'merchant', label: 'Merchant'}});
			}
			
			if(res.data.membershiplist.membership_img !== null){
				this.setState({old_membership_img:res.data.membershiplist.membership_img});
			}
			if(res.data.membershiplist.chat_options !== null){
				this.setState({selectedOptionChat:res.data.membershiplist.chat_options});
			}
			if(res.data.membershiplist.unlimited_calls !== null){
				this.setState({selectedOptionUnlimitedCall:res.data.membershiplist.unlimited_calls});
			}
			if(res.data.membershiplist.ai_support !== null){
				this.setState({selectedOptionAI:res.data.membershiplist.ai_support});
			}
			if(res.data.membershiplist.bank_statement !== null){
				this.setState({selectedOptionbankStm:res.data.membershiplist.bank_statement});
			}
			
			/*if(res.data.membershiplist.chat_options == 0 && res.data.membershiplist.unlimited_calls == 0 && res.data.membershiplist.ai_support == 0 && res.data.membershiplist.bank_statement == 0){
				this.setState({showAmen:0})
			}else{
				this.setState({showAmen:1})
			}*/

			if(res.data.membershiplist.period === 'Year'){
				this.setState({selectedPeriodOption:{value: 'Year', label: 'Year'}});
			}else if(res.data.membershiplist.period === 'Month'){
				this.setState({selectedPeriodOption:{value: 'Month', label: 'Month'}});
			}

			var postObject = {
				id: res.data.membershiplist.membership_category_id,			
			};

			axios.post(apiUrl+"membership/plandetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let plandetail = res.data.plandetail;
					this.setState({selectedPlanOption:{value: plandetail['id'], label: plandetail['title']}});					
				}
			});
			
		console.log(this.state, 'ressss0');
		// console.log(res.data.membershiplist, 'resssss');

		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }

	onEditorChange( evt ) {
		this.setState( {
            description: evt.editor.getData()
        } );
    }

    handleChange = (r,selOption) => {
       console.log(selOption.value)
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'membership_option_for'){
			this.setState({selectedMembForOption:selOption})
			this.setState({  selectedMembForvalue : selOption.value});
		}else if(r == 'period'){
			this.setState({selectedPeriodOption:selOption})
			this.setState({  selectedPeriodvalue : selOption.value});
		}else if(r == 'plan'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
			/*if(selOption.value == '7'){
				this.setState({showAmen:1});
			}else{
				this.setState({showAmen:0});
			}*/
		}
		console.log(selOption.value, 'sdfg')
 	};

   	handleInputChange(event) {
		const {name, value} = event.target;      
		this.setState({
			[name]: value
		});	
    }

	componentDidMount() {
		document.title = PageTitle('Membership Edit');
    }

	
    handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
				};
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			var period = '';
			var plan = '';
			var membfor = '';

			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
			}else{
				status = formPayload.selectedvalue;
			}

			if(formPayload.selectedMembForvalue === ''){
				membfor =formPayload.selectedMembForOption.value;
			}else{
				membfor = formPayload.selectedMembForvalue;
			}
			// console.log(membfor,'-membfor')
			// console.log(formPayload.selectedMembForOption,'-selectedMembForOption')
			// console.log(formPayload.selectedMembForvalue,'-selectedMembForvalue')
	

			if(formPayload.selectedPeriodvalue === '' && formPayload.selectedPeriodvalue!== 0){
				period =formPayload.selectedPeriodOption.value;
			}else{
				period = formPayload.selectedPeriodvalue;
			}

			if(formPayload.selectedPlanvalue === '' && formPayload.selectedPlanvalue!== 0){
				plan =formPayload.selectedPlanOption.value;
			}else{
				plan = formPayload.selectedPlanvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				title: formPayload.m_title,
				description: formPayload.description,
				amount: formPayload.amount,
				freeleads: formPayload.freeleads,
				period: period,
				membfor: membfor,
				status:  status,
				plan:  plan,
				id: formPayload.memId,
				old_membership_img:formPayload.old_membership_img,
				chat_type:formPayload.selectedOptionChat,
				unlimited_calls:formPayload.selectedOptionUnlimitedCall,
				ai_support:formPayload.selectedOptionAI,
				bank_statement:formPayload.selectedOptionbankStm,
				//showAmen:formPayload.showAmen,
			};
			
			let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
			
				var icondata = formPayload.icon;
				formData.append('file',icondata)
				
			//console.log(postObject, 'postobj');
			axios.post(apiUrl+"membership/edit",formData).then(res => {
				if(res.data.status === "success"){	
					this.setState({Loading:false});
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
					this.setState({Loading:false});
				}
			});
		}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {m_title,description,amount,freeleads,selectedPeriodOption,selectedPlanOption} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!m_title) {
			formIsValid = false;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else if(m_title){
			$('.errortitle').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			$('.erroramount').html('');
		}

		if (!freeleads) {
			formIsValid = false;
			$('.errorfreeleads').html('<span class="errorspan">Please fill the field</span>');
		}else if(freeleads){
			$('.errorfreeleads').html('');
		}

		if (!selectedPeriodOption) {
			formIsValid = false;
			$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPeriodOption){
			$('.errorperiod').html('');
		}

		if (!selectedPlanOption) {
			formIsValid = false;
			$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPlanOption){
			$('.errorplan').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 
	  
	  handleCheckboxChange = (r,event) => {
    const { checked, value } = event.target;
	if(r=='chat_type'){
		this.setState({ selectedOptionChat: checked ? value : null });
	}else if(r=='unlimited_calls'){
		this.setState({ selectedOptionUnlimitedCall: checked ? value : null });
	}else if(r=='ai_support'){
		this.setState({ selectedOptionAI: checked ? value : null });
	}else if(r=='bank_statement'){
		this.setState({ selectedOptionbankStm: checked ? value : null });
	}
};

	  
  render() {
	
  	  const {selectedOption,selectedPeriodOption,selectedPlanOption,description,selectedOptionChat,selectedOptionUnlimitedCall,selectedOptionAI,selectedOptionbankStm,selectedMembForOption} = this.state;
		if(description!==undefined){
			var desc = description;	
		}
		let loaderimg = '';
		//let preview = '';

		var	preview = (this.state.icon_preview) ? 
		<img className="img_class" src={this.state.icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'membership/'+this.state.old_membership_img} alt="" />
		;
  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="membership" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>Membership Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.mem_plan_option} 
                            onChange={this.handleChange.bind(this, 'plan')}
                            />
							<div className="errorplan"></div>
	           </div> 
				

				<div className="form-group">
					<label> Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" pattern="[0-9*]" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				<div className="form-group">
					<label>No. of Free Leads<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="freeleads" onChange={this.handleInputChange} className="form-control" value={this.state.freeleads} />
					<div className="errorfreeleads"></div>
				</div>

				<div className="form-group">
					<label>Membership Image (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorimage"></div>  
				</div>

			   <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div>

			   <div className="form-group">					
					<label>Membership For<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="membership_for"
						   value={selectedMembForOption?selectedMembForOption:{ value: 'lender', label: 'Lender'}}
                            options={lang.common.membership_option_for} 
							isSearchable = {false}
                            onChange={this.handleChange.bind(this, 'membership_option_for')}
                            />
	           	</div>

 
<h4>Amenities</h4>
				<div className="form-group">
						
					    <input type="checkbox"
						  name="chat_type"
						  value="1"
						  checked={selectedOptionChat == 1}
						  onChange={this.handleCheckboxChange.bind(this,'chat_type')}/>
										<span>Dedicated customer chat support 24/7</span>
										
				</div> 
				
				<div className="form-group">
						
					    <input type="checkbox"
						  name="unlimited_calls"
						  value="1"
						  checked={selectedOptionUnlimitedCall == 1}
						  onChange={this.handleCheckboxChange.bind(this,'unlimited_calls')}/>
										<span>Unlimited calls 24/7</span>
									
				</div> 
				<div className="form-group">
						
					    <input type="checkbox"
						  name="ai_support"
						  value="1"
						  checked={selectedOptionAI == 1}
						  onChange={this.handleCheckboxChange.bind(this,'ai_support')}/>
										<span>Financial advise by AI</span>
									
				</div> 
				<div className="form-group">
						
					    <input type="checkbox"
						  name="bank_statement"
						  value="1"
						  checked={selectedOptionbankStm == 1}
						  onChange={this.handleCheckboxChange.bind(this,'bank_statement')}/>
										<span>Bank statement summary in 2 secs by AI</span>
									
				</div> 			   
	 			   
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label> Membership Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="m_title" onChange={this.handleInputChange} className="form-control" value={this.state.m_title} />
					<div className="errortitle"></div>
				</div>
				

				<div className="form-group">					
					<label>Period<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPeriodOption?selectedPeriodOption:{ value: 'Month', label: 'Month'}}
                            options={lang.common.period_option} 
                            onChange={this.handleChange.bind(this, 'period')}
                            />
							<div className="errorperiod"></div>
	           </div> 
			   <div className="form-group">					
					<label>Description:</label>
						{/* <textarea style={{minHeight: "165px"}} type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  /> */}
						<CKEditor
					data={desc}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errordescription"></div>
				</div>
               

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     membershipdetails: state.membershipdetails,
//     membershipedit: state.membershipedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (memId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,memId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));