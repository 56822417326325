import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from 'ckeditor4-react';
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			m_title:'',
			description:'',
			amount:'',
			freeleads:'',
			period:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedMembForOption:{value: 'lender', label: 'Lender'},
			selectedPeriodOption: "",
			selectedPlanOption: "",
			selectedvalue:'active',
			selectedMembForvalue:'lender',
			selectedPeriodvalue:'',
			selectedPlanvalue:'',
			icon_preview : '',
			selectedOptionChat:0,
			selectedOptionUnlimitedCall:0,
			selectedOptionAI:0,
			selectedOptionbankStm:0,
			//showAmen:0
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	  
	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt.editor.getData()
        } );
		}, 1000);
     
    }

	handleChange = (r,selOption) => {
		
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'membership_option_for'){
			this.setState({selectedMembForOption:selOption})
			this.setState({  selectedMembForvalue : selOption.value});
		}else if(r == 'period'){
			this.setState({selectedPeriodOption:selOption})
			this.setState({  selectedPeriodvalue : selOption.value});
		}else if(r == 'plan'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
			console.log(selOption.value,'-value')
			/*if(selOption.value == '7'){
				this.setState({showAmen:1});
			}else{
				this.setState({showAmen:0});
			}*/
		}
		console.log(selOption, 'selopt')
		console.log(r, 'r')
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		console.log(name + ' ~ ' + value, 'name')

  }
  onChangeHandlerold=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file
	  });
	};
	reader.readAsDataURL(file);
	console.log(file)
  }

  handleSubmit = () => {
	if(this.validateForm()){ 
		this.setState({Loading:true});
		const formdata = this.state;
		var qs = require('qs');
		var status = '';
		var period = '';
		var plan = '';
		var membfor = '';

		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}
		
		if(formdata.selectedMembForvalue === ''){
			membfor =formdata.selectedMembForOption.value;
		}else{
			membfor = formdata.selectedMembForvalue;
		}

		if(formdata.selectedPeriodvalue === '' && formdata.selectedPeriodvalue!== 0){
			period =formdata.selectedPeriodOption.value;
		}else{
			period = formdata.selectedPeriodvalue;
		}

		if(formdata.selectedPlanvalue === '' && formdata.selectedPlanvalue!== 0){
			plan =formdata.selectedPlanOption.value;
		}else{
			plan = formdata.selectedPlanvalue;
		}

		var postObject ={
			admin_id : localStorage.getItem("admin_id"),
			title: formdata.m_title,
			description: formdata.description,
			amount: formdata.amount,
			freeleads: formdata.freeleads,
			period: period,
			plan: plan,
			status:  status,
			membfor:  membfor,
			chat_type:formdata.selectedOptionChat,
			unlimited_calls:formdata.selectedOptionUnlimitedCall,
			ai_support:formdata.selectedOptionAI,
			bank_statement:formdata.selectedOptionbankStm,
			//showAmen:formdata.showAmen,
		}
		
		
		let formData = new FormData();
		for(let k in postObject) {
			formData.append(k, postObject[k]);
		}
		
		
		var icondata = formdata.icon;
		formData.append('file',icondata)


		// console.log(formData, '-formDaTa ADD');
		axios.post(apiUrl+"membership/add",formData).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html(this.props.history.push('/membership'));
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
		});

	}
		
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {m_title,description,amount,period,freeleads,selectedPeriodOption,selectedPlanOption} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!m_title) {
			formIsValid = false;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else if(m_title){
			$('.errortitle').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			$('.erroramount').html('');
		}

		if (!freeleads) {
			formIsValid = false;
			$('.errorfreeleads').html('<span class="errorspan">Please fill the field</span>');
		}else if(freeleads){
			$('.errorfreeleads').html('');
		}

		if (!selectedPeriodOption) {
			formIsValid = false;
			$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPeriodOption){
			$('.errorperiod').html('');
		}

		if (!selectedPlanOption) {
			formIsValid = false;
			$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPlanOption){
			$('.errorplan').html('');
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		console.log(file.name,'-preview')
	  } 
	  
	  
	  
	  handleCheckboxChange = (r,event) => {
    const { checked, value } = event.target;
	if(r=='chat_type'){
		this.setState({ selectedOptionChat: checked ? value : null });
	}else if(r=='unlimited_calls'){
		this.setState({ selectedOptionUnlimitedCall: checked ? value : null });
	}else if(r=='ai_support'){
		this.setState({ selectedOptionAI: checked ? value : null });
	}else if(r=='bank_statement'){
		this.setState({ selectedOptionbankStm: checked ? value : null });
	}
};

  render() {

	
	const {selectedOption,selectedPeriodOption,selectedPlanOption,selectedOptionChat,selectedOptionUnlimitedCall,selectedOptionAI,selectedOptionbankStm,selectedMembForOption} = this.state;
	
	

	var current = this;	
	var	preview = (this.state.icon_preview) ? 
	<img className="img_class" src={this.state.icon_preview} alt="" /> :'';
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="membership" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Membership</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>Membership Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.mem_plan_option} 
                            onChange={this.handleChange.bind(this, 'plan')}
                            />
							<div className="errorplan"></div>
	           </div> 
				

				<div className="form-group">
					<label> Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" pattern="[0-9*]" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				<div className="form-group">
					<label>No. of Free Leads<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="freeleads" onChange={this.handleInputChange} className="form-control" value={this.state.freeleads} />
					<div className="errorfreeleads"></div>
				</div>

				<div className="form-group">
					<label>Membership Image (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorimage"></div>  
				</div> 

			   <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           	</div> 

				<div className="form-group">					
					<label>Membership For<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="membership_for"
						   value={selectedMembForOption?selectedMembForOption:{ value: 'lender', label: 'Lender'}}
                            options={lang.common.membership_option_for} 
							isSearchable = {false}
                            onChange={this.handleChange.bind(this, 'membership_option_for')}
                            />
	           	</div> 
			
				<h4>Amenities</h4>
				<div className="form-group">
						
					    <input type="checkbox"
						  name="chat_type"
						  value="1"
						  checked={selectedOptionChat == 1}
						  onChange={this.handleCheckboxChange.bind(this,'chat_type')}/>
										<span>Dedicated customer chat support 24/7</span>
										
				</div> 
				
				<div className="form-group">
						
					    <input type="checkbox"
						  name="unlimited_calls"
						  value="1"
						  checked={selectedOptionUnlimitedCall == 1}
						  onChange={this.handleCheckboxChange.bind(this,'unlimited_calls')}/>
										<span>Unlimited calls 24/7</span>
									
				</div> 
				<div className="form-group">
						
					    <input type="checkbox"
						  name="ai_support"
						  value="1"
						  checked={selectedOptionAI == 1}
						  onChange={this.handleCheckboxChange.bind(this,'ai_support')}/>
										<span>Financial advise by AI</span>
									
				</div> 
				<div className="form-group">
						
					    <input type="checkbox"
						  name="bank_statement"
						  value="1"
						  checked={selectedOptionbankStm == 1}
						  onChange={this.handleCheckboxChange.bind(this,'bank_statement')}/>
										<span>Bank statement summary in 2 secs by AI</span>
									
				</div> 
						

			
			
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Membership Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="m_title" onChange={this.handleInputChange} className="form-control" value={this.state.m_title}/>
					<div className="errortitle"></div>
				</div>
			<div className="form-group">					
					<label>Period<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPeriodOption?selectedPeriodOption:""}
                            options={lang.common.period_option} 
                            onChange={this.handleChange.bind(this, 'period')}
                            />
							<div className="errorperiod"></div>
	           </div>
				<div className="form-group">					
					<label>Description:</label>
						{/* <textarea style={{minHeight: "180px"}} type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
					<div className="errordescription"></div>
				</div>
				 
				
			   

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));