import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";


	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			title:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active'			
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
	}

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => { 
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status = '';


	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		title : formdata.title,
		status: status
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"AdvertisementCategory/add",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/AdvertisementCategory'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {title} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!title){
		formIsValid = false;
		$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errortitle').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const {selectedOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="AdvertisementCategory" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Advertisement Category</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortitle"></div>
				</div>
			</div>
                  
			<div className="form-right">
			
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));