import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';
import { mediaUrl} from'../Config/Config';
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const membershipid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      membershipid: membershipid,
      membershiplistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :membershipid
    };

    axios.post(apiUrl+"membership/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ membershiplistdata: res.data.membershiplistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Membership View');
   }
  render() {
  if(this.state.membershiplistdata!==undefined){
    var membershiplistdata = this.state.membershiplistdata;
    var descNewStr = "";
    if(membershiplistdata.description){
      var htmlStr = membershiplistdata.description;
      descNewStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
      descNewStr = descNewStr.replace(/&nbsp;/ig, '');
    }
  }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="membership" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Membership</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                  <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Membership Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{membershiplistdata.category_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Membership <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{membershiplistdata.title}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Plan for <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{membershiplistdata.membfor}</p>
                        </div>
                      </div>
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{descNewStr}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${membershiplistdata.amount}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Period<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(membershiplistdata.period ? membershiplistdata.period + "ly" : "")}</p>
                        </div>
                      </div>
                     
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(membershiplistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                    

                      </div>
                      </div>
               {membershiplistdata.membership_img != null && membershiplistdata.membership_img != ''?<div>
                  <img src={mediaUrl+"membership/"+membershiplistdata.membership_img} alt='membership-img' />
               </div>:''}
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));