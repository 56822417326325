import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from "react-dom";

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import { Provider } from "react-redux";
import { getStore } from "./store";

//import * as serviceWorker from './serviceWorker';

import './common/css/font-awesome.min.css';

import Home from './components/Home/Home';
import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import InviteFriend from "./components/InviteFriend/InviteFriend";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";
import CompanyClone from "./components/Company/CompanyClone";
import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";
import UserImport from "./components/User/ImportUser";
import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";
import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';
import DashboardList from "./components/Dashboard/List";
import CmsList from "./components/Cms/List";
import CmsAdd from "./components/Cms/Add";
import CmsEdit from "./components/Cms/Edit";
import PackageList from "./components/Package/List";
import PackageAdd from "./components/Package/Add";
import PackageEdit from "./components/Package/Edit";
import Cmscontent from './components/Account/Cmscontent';
import CategoryList from "./components/Category/List";
import CategoryEdit from "./components/Category/Edit";
import CategoryAdd from "./components/Category/Add";
import CategoryView from "./components/Category/View";

import BrokerList from "./components/Broker/List";
import BrokerEdit from "./components/Broker/Edit";

import LenderList from "./components/Lender/List";
import LenderEdit from "./components/Lender/Edit";

import InvestorsList from "./components/Investors/InvestorsList";


import LoanConfigList from "./components/LoanConfiguration/List";
import LoanConfigEdit from "./components/LoanConfiguration/Edit";
import LoanConfigAdd from "./components/LoanConfiguration/Add";

import AnnualConfigList from "./components/AnnualRevenueConfiguration/List";
import AnnualConfigEdit from "./components/AnnualRevenueConfiguration/Edit";
import AnnualConfigAdd from "./components/AnnualRevenueConfiguration/Add";



import RatingCalculationList from "./components/RatingCalculation/List";
import RatingCalculationAdd from "./components/RatingCalculation/Add";
import RatingCalculationEdit from "./components/RatingCalculation/Edit";


import LoanTypesList from "./components/LoanTypes/List";
import LoanTypesEdit from "./components/LoanTypes/Edit";
import LoanTypesAdd from "./components/LoanTypes/Add";

import TabList from "./components/TabManagement/List";
import TabEdit from "./components/TabManagement/Edit";
import TabAdd from "./components/TabManagement/Add";
import TabView from "./components/TabManagement/View";

import Businesstypes from "./components/BusinessModules/Businesstypes";
import Businesscategories from "./components/BusinessModules/Businesscategories";
import Annualrevenue from "./components/BusinessModules/Annualrevenue";
import Businesstime from "./components/BusinessModules/Businesstime";
import Loantypes from "./components/BusinessModules/Loantypes";


import MemList from "./components/Membership/List";
import MemEdit from "./components/Membership/Edit";
import MemAdd from "./components/Membership/Add";
import MemView from "./components/Membership/View";

import MembershipCategoryList from "./components/MembershipCategory/List";
import MembershipCategoryAdd from "./components/MembershipCategory/Add";
import MembershipCategoryEdit from "./components/MembershipCategory/Edit";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqImport from "./components/Faq/ImportFaq";

import GuideList from "./components/Guide/List";
import GuideEdit from "./components/Guide/Edit";
import GuideAdd from "./components/Guide/Add";

import BSPList from "./components/BrokerSplitPayment/List";

import AiFeedList from "./components/AiFeed/List";
import AiImport from "./components/AiFeed/Import";

import TransactionList from "./components/TransactionHistory/List";
import TransactionView from "./components/TransactionHistory/View";

import LeadList from "./components/Leads/List";
import LeadView from "./components/Leads/View";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import FlagList from "./components/Flags/List";
import FlagEdit from "./components/Flags/Edit";
import FlagAdd from "./components/Flags/Add";
import FlagView from "./components/Flags/View";

import AdvertisementList from "./components/Advertisement/List";
import AdvertisementEdit from "./components/Advertisement/Edit";
import AdvertisementAdd from "./components/Advertisement/Add";

import AdvertisementCategoryList from "./components/AdvertisementCategory/List";
import AdvertisementCategoryAdd from "./components/AdvertisementCategory/Add";
import AdvertisementCategoryEdit from "./components/AdvertisementCategory/Edit";

import './common/css/custom.css';

/*ReactDOM.render(
		<Router>
			<Switch>
				<Route exact path='/' component={Login}/>
				<Route path="/home" component={Home} />
				
			</Switch>
		</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();*/

const store = getStore();
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/profile" component={Adminprofile} />
        <Route path="/logout" component={Logout} />
        <Route path="/setting" component={Setting} />
        <Route path="/invitefriend" component={InviteFriend} />
        <Route path="/changepassword" component={Changepassword} />
        <Route path="/forget_password" component={Forgetpassword} />
        <Route path="/reset_password/:key" component={Resetpassword} />
        <Route path="/company" component={Company} />
        <Route path="/company-add" component={CompanyAdd} />
        <Route path="/company-edit/:ID" component={CompanyEdit} />
        <Route path="/company-clone/:ID" component={CompanyClone} />
        <Route path="/font-edit/:ID" component={FontEdit} />
        <Route path="/fonts" component={Fontlist} />
        <Route path="/font-add" component={FontAdd} />

		    <Route path="/user" component={UserList} />
        <Route path="/edit/:ID" component={UserEdit} />
        <Route path="/view/:ID" component={UserView} />
        <Route path="/user-add" component={UserAdd} />
        <Route path="/user-import" component={UserImport} />
		
		    <Route path="/broker" component={BrokerList} />
        <Route path="/editbroker/:ID" component={BrokerEdit} />

        <Route path="/lender" component={LenderList} />
        <Route path="/editlender/:ID" component={LenderEdit} />

        <Route path="/investors" component={InvestorsList} />

		
        <Route path="/templates" component={TemplateList} />
        <Route path="/template/add" component={TemplateAdd} />
        <Route path="/templates-edit/:ID" component={TemplateEdit} />
        <Route path="/cms/edit/:ID" component={CmsEdit} />
        <Route path="/cms" component={CmsList} />
        <Route path="/cms-add" component={CmsAdd} />
        <Route path="/dashboard" component={DashboardList} /> 
    
        <Route path="/package/edit/:ID" component={PackageEdit} />
        <Route path="/packages" component={PackageList} />
        <Route path="/package-add" component={PackageAdd} />

        <Route path='/plugincontent' component={Cmscontent} />

        <Route path='/loanconfiguration' component={LoanConfigList} />
        <Route path="/loanconfiguration-add" component={LoanConfigAdd} />
        <Route path="/editloanconfiguration/:ID" component={LoanConfigEdit} />

        <Route path='/annualconfiguration' component={AnnualConfigList} />
        <Route path="/annualconfiguration-add" component={AnnualConfigAdd} />
        <Route path="/editannualconfiguration/:ID" component={AnnualConfigEdit} />

        <Route path='/ratingCalculation' component={RatingCalculationList} />
        <Route path="/ratingCalculation-add" component={RatingCalculationAdd} />
        <Route path="/ratingCalculation-edit/:ID" component={RatingCalculationEdit} />


        <Route path='/loantype' component={LoanTypesList} />
        <Route path="/loantype-add" component={LoanTypesAdd} />
        <Route path="/editloantype/:ID" component={LoanTypesEdit} />

      

        <Route path="/tabmanagement" component={TabList} />
        <Route path="/tabmanagement-edit/:ID" component={TabEdit} />
        <Route path="/tabmanagement-view/:ID" component={TabView} />
        <Route path="/tabmanagement-add" component={TabAdd} />

        <Route path="/category" component={CategoryList} />
        <Route path="/category-edit/:ID" component={CategoryEdit} />
        <Route path="/category-view/:ID" component={CategoryView} />
        <Route path="/category-add" component={CategoryAdd} />

        <Route path="/businesstypes" component={Businesstypes} />
        <Route path="/businesscategories" component={Businesscategories} />
        <Route path="/annualrevenue" component={Annualrevenue} />
        <Route path="/businesstime" component={Businesstime} />
        <Route path="/loantypes" component={Loantypes} />

        <Route path="/membership" component={MemList} />
        <Route path="/membership-edit/:ID" component={MemEdit} />
        <Route path="/membership-view/:ID" component={MemView} />
        <Route path="/membership-add" component={MemAdd} />

        <Route path="/membershipcategory" component={MembershipCategoryList} />
        <Route path="/membershipcategory-add" component={MembershipCategoryAdd} />
        <Route path="/membershipcategory-edit/:ID" component={MembershipCategoryEdit} />

        <Route path="/faqcategory" component={FaqCategoryList} />
        <Route path="/faqcategory-add" component={FaqCategoryAdd} />
        <Route path="/faqcategory-edit/:ID" component={FaqCategoryEdit} />  

        <Route path="/faq" component={FaqList} />
        <Route path="/faq-add" component={FaqAdd} />
        <Route path="/faq-edit/:ID" component={FaqEdit} />
        <Route path="/faq-import" component={FaqImport} />

        <Route path="/guide" component={GuideList} />
        <Route path="/guide-edit/:ID" component={GuideEdit} />
        <Route path="/guide-add" component={GuideAdd} />

        <Route path="/brokersplitpayment" component={BSPList} />

        <Route path="/aifeed" component={AiFeedList} />
        <Route path="/aifeed-import" component={AiImport} />

        <Route path="/transactionhistory" component={TransactionList} />
        <Route path="/transactionhistory-view/:ID" component={TransactionView} />
        
        <Route path="/leads" component={LeadList} />
        <Route path="/lead-view/:ID" component={LeadView} />
		
		
		    <Route path="/dashboardmanagement/edit/:ID" component={DashboardEdit} />
        <Route path="/dashboardmanagement" component={DashboardManagement} />
        <Route path="/dashboardmanagement-add" component={DashboardAdd} />



        <Route path="/flags" component={FlagList} />
        <Route path="/flags-edit/:ID" component={FlagEdit} />
        <Route path="/flags-view/:ID" component={FlagView} />
        <Route path="/flags-add" component={FlagAdd} />
        
        <Route path="/advertisement" component={AdvertisementList} />
        <Route path="/advertisement-add" component={AdvertisementAdd} />
        <Route path="/advertisement-edit/:ID" component={AdvertisementEdit} />

        <Route path="/advertisementcategory" component={AdvertisementCategoryList} />
        <Route path="/advertisementcategory-add" component={AdvertisementCategoryAdd} />
        <Route path="/advertisementcategory-edit/:ID" component={AdvertisementCategoryEdit} /> 
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);